
import axios from "axios";
import {useEffect, useState} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import {Button, CardActions, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Modal} from "@mui/material";
import Box from "@mui/material/Box";
import Dates from "../common/dates";
import moment from 'moment';
import _ from "lodash";
const dict = {};
const MADict = {};



var today = moment().format("YYYY-MM-DD");
console.log(today)
var lastTradingDate = "";
for(var i = Dates.length-1; i>=0; i--){
    if(Dates[i] <= today){
        lastTradingDate = Dates[i];
        break;
    }
}
console.log("lastTradingDate", lastTradingDate);
var lastDayBeforeLastTradingDate = "";
for(var i = Dates.length-1; i>=0; i--){
    if(Dates[i] < lastTradingDate){
        lastDayBeforeLastTradingDate = Dates[i];
        break;
    }
}

export default function(props){
    const [stocksQueue, setStocksQueue] = useState([]);
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);
    const fetchStocksQueue = async ()=>{
        const maUrl = 'https://kesyn-1302292689.cos.ap-beijing.myqcloud.com/indicators/ma.txt'
        var maRes = await axios.get(maUrl);
        for(var line of maRes.data.split('\n')){
            if(line.indexOf("nan")>0){
                continue;
            }
            var parts = line.split(',')
            MADict[parts[0]] = Math.max(parts[2], parts[3]);
        }

        const url = `https://kesyn-1302292689.cos.ap-beijing.myqcloud.com/buy/${lastDayBeforeLastTradingDate}.txt`;
        var res = await axios.get(url);
        const indiUrl = "https://stock.deployment.cn/api/indi"
        var indiRes = await axios.get(indiUrl);
        var indiDict = {};
        for(var d of indiRes.data.result){
            indiDict[d.k1d.meta.code] = d;
        }
        // console.log(indiDict)
        var lines = res.data.split('\n');
        var stocks = [];
        for(var line of lines){
            if(line) {
                // console.log(line)
                if(!MADict[line.split(',')[0]]){
                    continue
                }
                if(!indiDict[line.split(',')[0].split("#")[1]]){
                    continue
                }
                // if(line.split(',')[0].split("#")[1].indexOf('3')!=0){
                //     continue;
                // }
                stocks.push({code:line.split(',')[0].split("#")[1], fullCode: line.split(',')[0],  price:line.split(',')[2]});
            }
        }
        var passedStock = [];
        var marketPricesUrl = 'https://tools.ai.deployment.cn/api/public/stock_zh_a_spot_em';
        var marketRes = await axios.get(marketPricesUrl);
        var marketPrices = marketRes.data;
        for(var marketPrice of marketPrices){
            if(indiDict[marketPrice["代码"]]){
                // marketPrice = {...marketPrice, ...indiDict[marketPrice["代码"]]}
                for(var key in indiDict[marketPrice["代码"]]){
                    marketPrice[key] = indiDict[marketPrice["代码"]][key];
                }
            } else{
                marketPrice.strength = 0;
            }
        }
        marketPrices = _.sortBy(marketPrices, c=>-c.strength);
        console.log(marketPrices)
        for(var marketPrice of marketPrices){
            if(marketPrice["名称"].indexOf("ST")>=0){
                continue;
            }
            var stock = stocks.find(c=>c.code == marketPrice["代码"]);

            if(stock){
                var maPrice = MADict[stock.fullCode];
                // console.log(maPrice, marketPrice["最新价"]);
                if(marketPrice["最新价"] <= maPrice){
                    continue;
                }
                if(marketPrice["最新价"] >stock.price ){
                    passedStock.push(marketPrice["代码"]);
                    // marketPrice["代码"] = {...marketPrice["代码"], ...indiDict[stock.code]}
                    for(var key in indiDict[stock.code]){
                        marketPrice[key] = indiDict[stock.code][key];
                    }
                    dict[marketPrice["代码"]] = marketPrice
                }
            }
        }
        console.log("passed", passedStock)

        setStocksQueue(passedStock);
    };
    useEffect(()=>{
        setFilteredStocks(stocksQueue);
    }, [stocksQueue])
    useEffect(() => {
        fetchStocksQueue().then();
        // setInterval(function(){
        //     fetchStocksQueue().then();
        // }, 10000)
    }, []);
    const renderPattern = (patterns)=>{
        return <div style={{color: 'white', width: '100%', display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10, height: 30, lineHeight: '30px'}}>
            {patterns.map((pattern, i)=>{
                return <div key={i}>
                    <div style={{fontSize: 12}}>
                        {pattern.name}
                    </div>
                    {/*<div  style={{fontSize: 12}}>*/}
                    {/*    {pattern.description}*/}
                    {/*</div>*/}
                </div>
            })}
        </div>
    }
    //`https://image.sinajs.cn/newchart/daily/n/${code >= "600000"? "sh" + code: "sz" + code }.gif`
    return <><div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        {filteredStocks.map((code) => {
            const stock = props.stocks[code >= "600000"? code + ".SH": code + ".SZ"];
            stock.code = code;
            return <Card sx={{ width: 400 }} style={{marginRight:10, marginBottom: 10}}  key={code}>
                <CardContent key={code}>
                    {/*<img src={`https://image.sinajs.cn/newchart/daily/n/${code >= "600000"? "sh" + code: "sz" + code }.gif`} style={{backgroundColor: 'white', width: '100%'}}/>*/}
                    <div style={{fontSize: '20px'}}>
                        {stock.name}
                    </div>
                    <div style={{fontSize: 15, color: 'red'}}>
                        {dict[code]["涨跌幅"]}% &nbsp;&nbsp; 强度:{dict[code].strength.toFixed(1)}
                    </div>
                    <div style={{fontSize: 12, color: 'rgba(255,255,255,0.5)'}}>
                        {code}
                    </div>
                    <div style={{color: '#90caf9', fontSize: 12}}>
                        {stock.indices}
                    </div>
                </CardContent>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    width="100%"
                    style={{backgroundColor:'white'}}
                    image={`https://stocks_imgs.middlewares.online/GetPic.aspx?nid=${stock.code>='600000'?1:0}.${stock.code}&type=&unitWidth=-6&ef=EXTENDED_BOLL&formula=KDJ&AT=1&imageType=KXL&timespan=${Math.floor(Date.now()/1000)}`}
                    onClick={()=>{
                        setSelectedStock(stock);
                    }}
                />
                {renderPattern(dict[code].k1d.patterns)}
                <CardMedia
                    component="img"
                    alt="green iguana"
                    width="100%"
                    style={{backgroundColor:'white'}}
                    image={`https://stocks_imgs.middlewares.online/GetPic.aspx?nid=${stock.code>='600000'?1:0}.${stock.code}&type=M60&unitWidth=-6&ef=EXTENDED_BOLL&formula=KDJ&AT=1&imageType=KXL&timespan=${Math.floor(Date.now()/1000)}`}
                    onClick={()=>{
                        setSelectedStock(stock);
                    }}
                />
                {renderPattern(dict[code].k60.patterns)}

                {/*<CardActions>*/}
                {/*    <Button size="small">待选</Button>*/}
                {/*    <Button size="small" color="error">隐藏</Button>*/}
                {/*    <Button size="small" variant="outlined">立即下单</Button>*/}
                {/*</CardActions>*/}
            </Card>
        })}
    </div>

        {selectedStock?<Dialog
            open={selectedStock !== null}
            onClose={()=>{setSelectedStock(null)}}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle style={{backgroundColor: 'black'}}>{selectedStock.name}</DialogTitle>
            <DialogContent style={{backgroundColor: 'black'}}>
                {selectedStock&&<iframe style={{width: '100%', height: 500}}
                    src={`https://quote.eastmoney.com/basic/h5chart-iframe.html?code=${selectedStock.code}&market=${selectedStock.code>='600000'?1:2}&type=r`}></iframe>}
            </DialogContent>
            <DialogActions style={{backgroundColor: 'black'}}>
                <Button size="small">待选</Button>
                <Button size="small" color="error">隐藏</Button>
                <Button size="small" variant="outlined">立即下单</Button>
            </DialogActions>
        </Dialog>:null}
    </>
}
