import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useState} from "react";
import Quant from "./Components/Quant";
import Select from "./Components/Select"
import PatternSelect from "./Components/PatternSelect";

const stocks = require('./stocks.json');

function TabContainer(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


function App() {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="形态选股"/>
              <Tab label="普通选股"/>
            {/*<Tab label="量化操控" />*/}
          </Tabs>
        </Box>
        {/*<TabContainer value={value} index={10}>*/}
        {/*  <Quant stocks={stocks}></Quant>*/}
        {/*</TabContainer>*/}
          <TabContainer value={value} index={0}>
            <PatternSelect stocks={stocks}></PatternSelect>
          </TabContainer>
        <TabContainer value={value} index={1}>
            <Select stocks={stocks}></Select>
        </TabContainer>
      </Box>
  );
}

export default App;
